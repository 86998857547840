<script>
import api from '@/command/api'
import { Tabs } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import DistributionList from './distributionList.vue'
import PendingList from './pendingList.vue'
import { getAction } from '@/command/netTool'
export default {
  name: 'distributionManage',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 1,
      count: 0,
    }
  },
  mounted() {
    this.initProcessCount()
  },

  methods: {
    initProcessCount() {
      getAction('/api/farmSalesUser/queryProcessCount').then((res) => {
        this.count = res.data.processCount || 0
      })
    },
  },
  render() {
    return (
      <div>
        <Tabs v-model:activeKey={this.activeKey} destroyInactiveTabPane={true}>
          <Tabs.TabPane
            key="1"
            tab={
              <span>
                <a-icon style="font-size: 16px;" type="setting" />
                分销员列表
              </span>
            }
          >
            <DistributionList />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="2"
            tab={
              <span>
                <a-badge count={this.count} offset={[10, 0]}>
                  <a-icon style="font-size: 16px;" type="setting" />
                  待审批
                </a-badge>
              </span>
            }
            // forceRender={true}
          >
            <PendingList onInit={this.initProcessCount} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.template-card {
  min-height: calc(100vh - 188px);
}
/deep/.ant-popover-message-title {
  max-width: 200px;
}
/deep/.ant-tabs-nav-scroll {
  background: #fff;
}
</style>
